<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="color3 color3Text--text"
        fab bottom right fixed
      >
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Add An Organization</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" @click.stop="dialog = false" fab small>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="window" touchless>
          <v-window-item :value="0">
            <organization-search
              :active="window === 0"
              :selector="true"
              @profile-selected="onProfileSelected"
              ref="search"
              :exclude="exclude"
            ></organization-search>
          </v-window-item>
          <v-window-item :value="1">
            <template v-if="selectedProfile">
              <div class="title text-center pb-4">Add {{selectedProfile.name}}?</div>
              <div style="height: 100px">
                <v-img :src="selectedProfile.theme.logoUrl" contain width="100%" max-height="90%"></v-img>
              </div>
            </template>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-expand-transition>
        <div v-if="window===1">
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn
              color="success"
              @click.stop="addOrganization"
              :loading="loading"
            >yes</v-btn>
            <v-btn
              color="error"
              @click.stop="window=0"
              :disabled="loading"
            >no</v-btn>
          </v-card-actions>
        </div>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import OrganizationSearch from '@/components/Organization/OrganizationSearch.vue'

export default {
  props: ['username', 'exclude', 'ratingSystem'],
  data () {
    return {
      dialog: false,
      window: 0,
      selectedProfile: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user', 'getPageInfo']),
    page () {
      return this.getPageInfo(this.username)
    }
  },
  methods: {
    onProfileSelected (profile) {
      this.selectedProfile = profile
      this.window = 1
    },
    addOrganization () {
      this.loading = true
      this.$VBL.ratings.systemOrganizations.post(this.ratingSystem.id, this.selectedProfile.id)
        .then(r => {
          this.$emit('added', r.data)
          this.dialog = false
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => { this.loading = false })
    }
  },
  components: {
    OrganizationSearch
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.window = 0
        if (this.$refs.search) {
          this.$refs.search.reset()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
